import React, { useContext, useEffect } from 'react';
import AppContext from "@src/context"
import Seo from '@components/utility/SEO';
import { KfHeroUtility } from "@klickinc/kf-react-components";
import { Link } from 'gatsby';
//style imports
import './styles.scss';

const SignupError = () => {
    const ctx = useContext(AppContext);

    useEffect(() => {
        ctx.setPageClass('page--sign-up-error');
    }, []);

    return (
        <>
            <div className='page--sign-up-error'>
                <div className='container h-[25rem]'>
                    <section>
                        <KfHeroUtility addedClass='heading heading-hero-blue'>Sorry!</KfHeroUtility>
                        <p>It appears something has gone wrong on our end. Please try again later. <br className='hidden md:block' />We apologize for the inconvenience.</p>
                    </section>
                    <div className="text-center sm:text-left mb-28 mt-8">
                        <Link to='/' className='link-btn link-btn--blue sm:max-w-[18.75rem] md:max-w-[16rem] link-hero-dark-blue'>Return Home</Link>
                    </div>
                </div>
                <div className="w-screen signupError">
                </div>
            </div>
        </>
    );
};

export default SignupError;

export function Head() {
    return (
        <Seo title='Sign Up Error - EMFLAZA® (deflazacort)' addSocialMeta={false}>
            <meta name="robots" content="noindex" />
        </Seo>
    );
};
